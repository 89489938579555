// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-confirm-js": () => import("./../../../src/pages/confirm.js" /* webpackChunkName: "component---src-pages-confirm-js" */),
  "component---src-pages-in-place-client-js": () => import("./../../../src/pages/in-place-client.js" /* webpackChunkName: "component---src-pages-in-place-client-js" */),
  "component---src-pages-in-place-js": () => import("./../../../src/pages/in-place.js" /* webpackChunkName: "component---src-pages-in-place-js" */),
  "component---src-pages-in-place-redirect-js": () => import("./../../../src/pages/in-place-redirect.js" /* webpackChunkName: "component---src-pages-in-place-redirect-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-v-2-login-js": () => import("./../../../src/pages/v2/login.js" /* webpackChunkName: "component---src-pages-v-2-login-js" */)
}

