export default (user, action) => {
  switch (action.type) {
    case 'SAVE_USER':
      return {
        ...user,
        isLoggedIn: true,
        data: action.payload,
      }
    case 'LOGOUT':
      return {
        ...user,
        isLoggedIn: false,
        data: {},
      }
    case 'OAUTH_REQUEST':
      return {
        ...user,
        oauthRequest: true,
        oauthData: action.payload,
      }
    case 'OAUTH_REQUEST_DONE':
      return {
        ...user,
        oauthRequest: false,
        oauthData: null,
      }
    case 'CHANGE_LOCALE':
      return {
        ...user,
        locale: action.payload,
      }

    default:
      return user
  }
}
